import React from 'react'
import Logo from "./symphony.svg"

import "./Dialog.scss"

function Dialog() {
    let cerrar = ()=>{
        document.getElementById("Dialog").classList.remove("activo")
    }
    return (
        <div id="Dialog" className="">
            <div className="dialogBox">
            <div style={{position:"absolute",top:"10px",right:"10px"}} onClick={cerrar}>X</div>
                <div className="MtLogo">
                    <img src={Logo} alt="Mt"/>
                </div>
                <div className="DataMt">
                <p>MedicareSoft SRL</p>
                </div>
            </div>
        </div>
    )
}

export default Dialog
