import React,{useState,useContext} from 'react'
import './Login.scss'
import {ContextoGeneral} from './../../App'
import jwt from "jsonwebtoken"

const Contexto = React.createContext('')
function Login(props) {
    const [enviando, setenviando] = useState(false)
    const [valores, setvalores] = useState({username:'',password:''})
    const [error, seterror] = useState('')
    let valor = {
        enviando:enviando,
        setenviando:setenviando,
        valores:valores,
        setvalores:setvalores,
        seterror:seterror,
        props:props
    }
    return (
        <Contexto.Provider value={valor}>
            <div id='Login'>
                <TarjetaLogin>
                    <Logo/>
                    <div className='LoginContenedor'>
                        <div style={{'width':'100%'}}>
                            <Input etiqueta='Usuario' tipo='text' valor={valores.username}/>
                            <Input etiqueta='Password' tipo='password' valor={valores.password}/>
                            <div style={{'textAlign':'center'}}>{error}</div>
                            <IngresarBtn disable={enviando}/>
                        </div>
                    </div>
                </TarjetaLogin>
            </div>
        </Contexto.Provider>
    )
}
let IngresarBtn = (props)=>{
    let contexto = useContext(Contexto)
    let contextogeneral = useContext(ContextoGeneral)
    let Enviar = ()=>{
        EnviarDatos(contexto.valores,contexto,contextogeneral)
        contexto.setenviando(true)
    }
    let boton = props.disable?<Enviando/>:<span className='LoginBtn' onClick={Enviar}>
        <img src='./IMG/flechalogin.png' style={{"width":"-webkit-fill-available"}}/>
    </span>
    return(
        <div className='ContenedorBtnLogin'>
            {boton}
        </div>
    )
}
let Enviando = ()=>{
    return(
        <div className='Enviando'>
            <div className='Spinner'></div>
        </div>
    )
}
let Input = (props)=>{
    let contexto = useContext(Contexto)
    let CambiarValor = (e)=>{
        let objeto = contexto.valores
        if(props.etiqueta === 'Usuario'){
            objeto.username = e.target.value
        }else{
            objeto.password = e.target.value
        }
        contexto.setvalores({...objeto})
    }
    return(
        <div className='ContenedorInput'>
            <span className='InputLabel'>{props.etiqueta}</span>
            <input type={props.tipo} value={props.valor} onChange={CambiarValor}/>
        </div>
    )
}
let Logo = ()=>{
    return(
        <div className='Logo'>
            <div className='img'>
                <div className='imgF'>
                    <img src='./IMG/logoletras.svg' style={{"width":"-webkit-fill-available"}}/>
                </div>
            </div>
        </div>
    )
}
let TarjetaLogin = (props)=>{
    return(
        <div className='TarjetaLogin'>{props.children}</div>
    )
}

function EnviarDatos(data,contexto,contextogeneral){
    let configuraciones = JSON.parse(window.localStorage.getItem("ConfiguracionGeneral"))
    let url = `${configuraciones.ServidorDicom}/login`;
    let token = {token:jwt.sign(data,"Medicaltec310188")}
    let metodo = {
        method: 'POST',
        body: JSON.stringify(token),
        headers: {
            'Content-Type': 'application/json'
        }
    }
    fetch(url,metodo)
    .then((res)=>{
        if(res.status === 200){
            contexto.setvalores({username:'',password:''})
            contexto.setenviando(false)
        }
        return res.json()
    })
    .then((data)=>{
        if(!data.db){
            contexto.seterror('Error de Autenticacion')
        }else{
            contextogeneral.setusuario({...data.db.usuario})
            //contextogeneral.setconfiguraciones()
            localStorage.setItem('MedId',data.db.token)
            contextogeneral.setiniciado({iniciado:true,autenticado:true})
            contexto.seterror('Acceso Correcto')
            contexto.props.history.push('/main')
        }
    })
}
export default Login
